import { apiWithToken } from '.';
import {
  NewEntryRequestObj,
  UpdateEntryRequestObj,
  FEERecord,
  Consumption,
} from '@/src/types/schemas/inputModule/entry';
import { AppConfigType } from '@/src/types/schemas/inputModule/config';
import { UserInfoType } from '@/src/types/schemas/inputModule/user-info';

const baseURL = import.meta.env.VITE_ENDPOINT;

type GetEntriesParams = {
  vesselId: string;
  limit?: number;
  monthsBack?: number;
};

// GET CONFIG
export async function getConfig(): Promise<AppConfigType | undefined> {
  const response = await apiWithToken.get(baseURL + '/types/settings');
  return response.data;
}

// FETCH VESSEL ENTRIES
export async function getEntries({
  vesselId,
  limit = 100,
  monthsBack = 12,
}: GetEntriesParams): Promise<FEERecord[] | undefined> {
  const response = await apiWithToken.get(
    baseURL + `/vessels/${vesselId}/fee/entries?limit=${limit}&monthsBack=${monthsBack}`
  );
  return response.data;
}

// CREATE NEW ENTRY(IES)
export async function postEntry(vesselId: string, entries: NewEntryRequestObj[]) {
  await apiWithToken.post(baseURL + `/vessels/${vesselId}/fee/entries`, entries, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// UPDATE ENTRY (ALL FIELDS EXCEPT CONSUMPTIONS)
export async function patchEntry(vesselId: string, entryId: string, update: UpdateEntryRequestObj) {
  const response = await apiWithToken.patch(
    baseURL + `/vessels/${vesselId}/fee/entries/${entryId}`,
    update,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );
  return response.data;
}

// DELETE ENTRY
export async function deleteEntry(vesselId: string, entryId: number) {
  await apiWithToken.delete(baseURL + `/vessels/${vesselId}/fee/entries/${entryId}`);
}

// CREATE CONSUMPTIONS (WHERE AN ENTRY IS UPDATED AND A NEW CONSUMPTION IS ADDED)
export async function addNewEntryConsumptions(
  vesselId: string,
  entryId: number,
  consumptions: Consumption[]
) {
  const response = await apiWithToken.post(
    baseURL + `/vessels/${vesselId}/fee/entries/${entryId}/consumptions`,
    consumptions,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
}

// UPDATE CONSUMPTIONS
export async function updateEntryConsumptions(
  vesselId: string,
  entryId: number,
  consumptions: Consumption[]
) {
  const response = await apiWithToken.put(
    baseURL + `/vessels/${vesselId}/fee/entries/${entryId}/consumptions`,
    consumptions,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
}

// DELETE CONSUMPTION
export async function deleteConsumption(
  vesselId: string,
  entryId: number,
  consumptionId: number
): Promise<void> {
  await apiWithToken.delete(
    baseURL + `/vessels/${vesselId}/fee/entries/${entryId}/consumptions/${consumptionId}`
  );
}

// FETCH USER INFO
export async function getUserInfo(userId?: string): Promise<UserInfoType | undefined> {
  if (!userId) return;
  const response = await apiWithToken.get(baseURL + '/user-info');
  return await response.data;
}
